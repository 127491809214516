.tableContainer {
  width: 100%;
  border-spacing: 0;
  text-align: center;
  /* border: 1px solid black; */
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: white;
}

.tableRow {

}

.tableRow:nth-child(even) {
  background-color: #e7e7e7;
}

.tableRow:nth-child(odd) {
  background-color: #f7f7f7;
}

.tableCell {
  margin: 0;
  padding: 0.5rem;
  /* border-bottom: 1px solid black;
  border-right: 1px solid black; */
}
