.adminPanelForm {
  position: absolute;
  top: 16px;
  left: 32px;
  width: calc(100% - 130px);
  max-width: 800px;
  z-index: 3;
}

.adminPanelFormButton {
  display: none;
}


@media only screen and (max-width: 767px) {
  .adminPanelForm {
    /* opacity: 0;
    visibility: hidden; */
    background-color: white;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  }

  .adminPanelFormButton {
    display: block;
  }

  .showFilter {
    opacity: 1;
    visibility: visible;
  }

  .hiddenFilter {
    opacity: 0;
    visibility: hidden;
  }
}
