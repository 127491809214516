.controlPanel {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  z-index: 100;
  padding: 8px 16px;
}

.tagBox {
  width: 350px;
}

.overYield {
  margin: 8px 0;
  padding: 8px;
  width: auto;
  text-align: center;
  background-color: orangered;
  color: white;
  border-radius: 4px;
}

.clasatest {
    font-weight: bold;
}


@media only screen and (max-width: 770px) {
    .tagBox {
    width: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .controlPanel {
    flex-direction: column;
  }

  .tagBox {
    width: 100%;
  }

  .overYield {
    margin: 8px 0;
    padding: 8px 4px;
    width: 100%;
    text-align: center;
    background-color: orangered;
    color: white;
    border-radius: 4px;
  }
}
