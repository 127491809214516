.menuList {
	width: 250px;
	height: calc(100% - 26px);
}

.menuListItem {
	color: white !important;
	text-decoration: none;
	display: flex;
	align-items: center;
}

.menuListItem > span {
	margin-left: 8px;
}
