.loginWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.formWrapper {
	width: 100%;
	max-width: 450px;
	height: auto;
	padding: 16px;
	box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.6);
}

.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	transform: rotateY(180deg);
}

.bg2 {
	position: absolute;
	top: 0;
	right: 0;
}

.bg3 {
	margin: 0 auto 25px;
}

.loginButton {
	display: table;
	margin: 17px auto 0;
}
